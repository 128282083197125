export enum GlobalActionsEnum {
  TOGGLE_THEME = 'TOGGLE_THEME'
}

export enum SearchActionsEnum {
  LOAD_TAGS = 'LOAD_TAGS'
}

export enum Wh00tActionsEnum {
  WINDOW_MIN = 'WINDOW_MIN',
  WINDOW_MED = 'WINDOW_MED',
  WINDOW_MAX = 'WINDOW_MAX',
  NEW_MESSAGE = 'NEW_MESSAGE',
  HISTORICAL_MESSAGE = 'HISTORICAL_MESSAGE',
  CLEAR_MESSAGES = 'CLEAR_MESSAGES',
  DISCONNECTED = 'DISCONNECTED',
  SECRET_MESSAGE = 'SECRET_MESSAGE',
  INTERNAL_ALERT_ON = 'INTERNAL_ALERT_ON',
  INTERNAL_ALERT_OFF = 'INTERNAL_ALERT_OFF',
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  CONNECTION_ERROR = 'CONNECTION_ERROR'
}

export enum Wh00tMessageTypeEnum {
  LOCAL = 'LOCAL',
  HISTORY = 'HISTORY',
  SOCKET = 'SOCKET',
}

export enum Wh00tWindowStateEnum {
  MIN = 'MIN',
  MED = 'MED',
  MAX = 'MAX',
}

export enum ThemeModeEnum {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export enum LocalStorageEnum {
  THEME = 'THEME',
  USERNAME ='USERNAME',
  STAY_CONNECTED ='STAY_CONNECTED',
  CHAT_STATE = 'CHAT_STATE'
}
